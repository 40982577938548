import { useState, useEffect } from "react";
import SelectDate from "../Components/SelectDate";
import Calendar from "../Components/Calendar";
import DayCard from "../Components/DayCard";
import DayDetails from "../Components/DayDetails";
import CalendarLegend from "../Components/CalendarLegend";
import Preferences from "../Components/Preferences";
import Information from "../Components/Information";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useCalendarEventContext } from "../Context/CalendarEventContext";
import api from "../api/api";
import { createTeamUitjeRequest } from "../api/backend";
import { DateTime } from "luxon";
import LoadingOverlay from "../Components/LoadingOverlay";
import { usePublicSettingsContext } from "../Context/PublicSettingsContext";
import { formatDateFromISO } from "../Utils/date";
import useAvailableCustomEvents from "../Hooks/useAvailableCustomEvents";

export default function TeamuitjesSignup({ page }) {

    const [privacyAgreed, setPrivacyAgreed] = useState(false);

    // Form data
    const [selectedDay, setSelectedDay] = useState(null);
    const [unavailableDayParts, setUnavailableDayParts] = useState({});
    const [partOfday, setPartOfday] = useState('');
    const [visitorCount, setVisitorCount] = useState(12);
    const [lunch, setLunch] = useState(false);
    const [visitorInformation, setVisitorInformation] = useState({
        0: {
            firstName: '',
            lastName: '',
            emailAddress: '',
            phoneNumber: '',
        }
    });
    const [companyInformation, setCompanyInformation] = useState({
        companyName: '',
        department: '',
        streetAndNumber: '',
        zipcode: '',
        city: '',
    });

    // Form
    const [invalid, setInvalid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState('');

    const [message, setMessage] = useState('');

    const handleSubmitForm = async (e) => {

        e.preventDefault();

        if (loading)
            return;

        setLoading(true);
        setNotifications([]);
        let hasError = false;

        if(!privacyAgreed) {
            setNotifications(prevState => ([...prevState, "privacyAgreed"]))
            hasError = true;
        }

        if (!selectedDay) {
            setNotifications(prevState => ([...prevState, "selectedDay"]))
            hasError = true;
        }

        if (!partOfday) {
            setNotifications(prevState => ([...prevState, "partOfday"]))
            hasError = true;
        }

        if (!e.target.checkValidity()) {
            setNotifications(prevState => ([...prevState, "formfields"]))
            hasError = true;
        }

      

        if (hasError) {
            setInvalid(true);
            setLoading(false);
            return null;
        }

        const formData = {
            amountOfParticipants:   visitorCount,
            comments:               message,
            date:                   DateTime.fromFormat(selectedDay, 'dd-MM-yyyy').toISODate(),
            daypart:                partOfday,
            department:             companyInformation.department,
            emailAddress:           visitorInformation[0].emailAddress,
            firstName:              visitorInformation[0].firstName,
            lastName:               visitorInformation[0].lastName,
            lunch:                  lunch,
            organizationAddress:    companyInformation.streetAndNumber,
            organizationCity:       companyInformation.city,
            organizationName:       companyInformation.companyName,
            organizationPostalCode: companyInformation.zipcode,
            phoneNumber:            visitorInformation[0].phoneNumber
        }

        const request = await createTeamUitjeRequest(formData);

        if ([200, 201, 204].includes(request.status)) {
            window.location.href = 'https://stichtingjarigejob.nl/bedankpagina-teamuitje/';
            return;
        }

        setLoading(false);

    }

    return (
        <div>
            <div className="flex flex-col h-full">
                <div className="lg:w-[70vw] p-2 lg:p-5 lg:mx-auto">
                    <h1 className="font-proxima font-black text-2xl lg:text-4xl text-cal-blue">Inschrijven teamuitje</h1>
                </div>
                <div className="lg:w-[70vw] mx-auto lg:p-5">
                    <form
                        onSubmit={handleSubmitForm}
                        className={`bg-[#FAFAFA] ${invalid ? "display-errors" : ""}`}
                        noValidate
                    >
                        <SelectDate page={page} />
                        <CalendarLegend />
                        <ClientCalendar
                            selectedDay={selectedDay}
                            setSelectedDay={setSelectedDay}
                            setUnavailableDayParts={setUnavailableDayParts}
                            setPartOfday={setPartOfday}
                            page={page}
                        />
                        <Preferences
                            partOfday={partOfday}
                            selectedDate={selectedDay}
                            setPartOfday={setPartOfday}
                            unavailableDayParts={unavailableDayParts}
                            visitorCount={visitorCount}
                            setVisitorCount={setVisitorCount}
                            lunch={lunch}
                            setLunch={setLunch}
                        />
                        <Information
                            page={page}
                            visitorInformation={visitorInformation}
                            setVisitorInformation={setVisitorInformation}
                            visitorCount={visitorCount}
                            companyInformation={companyInformation}
                            setCompanyInformation={setCompanyInformation}
                            message={message}
                            setMessage={setMessage}
                            invalid={invalid}
                            loading={loading}
                            notifications={notifications}
                            privacyAgreed={privacyAgreed}
                            setPrivacyAgreed={setPrivacyAgreed}
                        />
                    </form>
                </div>
            </div>
        </div>
    );
}

/**
 * Properties -> React.Element
 * @param properties.page String
 * @param properties.selectedDay Null | String
 * @param properties.setPartOfday String -> Any
 * @param properties.setSelectedDay String -> Any
 * @param properties.setUnavailableDayParts Array String -> Any
 * @return React.Element
 */
function ClientCalendar(properties) {

    const {
        setUnavailableDayParts,
        selectedDay,
        setSelectedDay,
        setPartOfday,
        page
    } = properties;

    const {
        unavailableDates,
        calendarEvents,
        teamuitjeLoading,
        unavailableDateLoading
    } = useCalendarEventContext();

    const { publicSettings } = usePublicSettingsContext();

    const availableCustomEvents = useAvailableCustomEvents();

    const calendarAvailableTill = formatDateFromISO(publicSettings?.teamuitjeCalendarEndDateExclusiveUpperBound || "2024-10-01T00:00:000.Z");

    const dataLoading = teamuitjeLoading || unavailableDateLoading;

    const selectDay = (date) => {
        let unavailabilityObject = (unavailableDates || []).find(x => x.date == date);
        let partUnavailable = false;
        if(unavailabilityObject?.teamuitjeMorningUnavailable) {
            partUnavailable = "morning";
        } else if(unavailabilityObject?.teamuitjeAfternoonUnavailable) {
            partUnavailable = "afternoon";
        }
        setSelectedDay((prevDate) => prevDate == date ? null : date)
        const dayPartsTaken = Object.values(calendarEvents[date] || [])
            .filter(x => x.$$type == 'teamuitje')
            .map((x) => x.daypart);
        if(partUnavailable) {
            dayPartsTaken.push(partUnavailable);
        }
        setUnavailableDayParts(dayPartsTaken);
        if (dayPartsTaken.length == 1) {
            setPartOfday(dayPartsTaken.includes("afternoon") ? "morning" : "afternoon");
        } else {
            setPartOfday(partUnavailable);
        }
    }

    let dayCards = {};
    
    // Als een datum niet beschikbaar is
    if (unavailableDates) {
        Object.values(unavailableDates).map(x => {
            let availabilityText = "Niet beschikbaar";
            let partiallyAvailable = false;
            let fullyAvailable = false;
            if(x.teamuitjeMorningUnavailable && !x.teamuitjeAfternoonUnavailable) {
                availabilityText = 'ochtend niet meer beschikbaar';
                partiallyAvailable = null == Object
                    .values(calendarEvents[x.date] ?? [])
                    .find(event => {
                        return event.$$type === 'teamuitje' &&
                            event.daypart === 'afternoon';
                    });
            }
            if(x.teamuitjeAfternoonUnavailable && !x.teamuitjeMorningUnavailable) {
                availabilityText = 'middag niet meer beschikbaar';
                partiallyAvailable = null == Object
                    .values(calendarEvents[x.date] ?? [])
                    .find(event => {
                        return event.$$type === 'teamuitje' &&
                            event.daypart === 'morning';
                    });
            }
            if(!x.teamuitjeMorningUnavailable && !x.teamuitjeAfternoonUnavailable) {
                fullyAvailable = true;
            }
            if(partiallyAvailable) {
                dayCards[x.date] = {
                    dayLabelClass: 'text-black',
                    clickable: true,
                    component: <DayCard>
                        <div className="flex flex-col h-full justify-center p-2 w-full">
                            <ul className="flex flex-col text-left gap-1">
                                <li className="flex flex-row gap-2 items-center text-xs">
                                    <div className="aspect-square bg-red-400 h-2 w-2" />
                                    {availabilityText}
                                </li>
                            </ul>
                        </div>
                    </DayCard>
                } 
			} else if(!fullyAvailable) {
                dayCards[x.date] = {
                    dayLabelClass: 'text-black',
                    clickable: false,
                    component: <DayCard>
                        <div className="bg-cal-lighter-gray flex h-full items-center p-2 w-full">
                            <p className="invisible lg:visible text-left">Niet beschikbaar</p>
                        </div>
                    </DayCard>
                } 
            }
        }) 
    }

    if(availableCustomEvents) {
        Object.entries(availableCustomEvents).map(([date, item]) => {
            dayCards[date] = {
                dayLabelClass: 'text-black',
                clickable: false,
                component: <DayCard>
                    <div className="p-2 bg-cal-lighter-gray text-black w-full h-full flex items-center">
                        <p className="invisible lg:visible text-left">Niet beschikbaar</p>
                    </div>
                </DayCard>
            } 
        });
    }
    
    Object.keys(calendarEvents).map((date) => {

        // Als een datum niet beschikbaar is
        if(dayCards[date]) return;


        // Filter alle events er uit die geen Teamuitjes zijn
        let events = Object.values(calendarEvents[date])
            .filter((event) => {
                if (event.$$type !== "teamuitje") return false;
                return true;
            }).map((event) => {
                let daypart = event.daypart == "morning" ? "ochtend" : "middag";
                daypart = event.daypart ? `${daypart}` : '';
                return <li key={event.$id} className="text-xs flex flex-row gap-2 items-center">
                    <div className={`w-2 h-2 aspect-square bg-red-400`}></div>
                    {daypart} niet meer beschikbaar
                </li>
            });

        if (events.length == 0) return;

        // Stoppen als een datum al is volgeboekt ( 2 of meer boekingen voor teamuitjes ).
        if (events.length >= 2) {

            dayCards[date] = {
                clickable: false,
                dayLabelClass: 'text-black',
                component: <DayCard>
                    <div className="p-2 bg-cal-lighter-gray w-full h-full flex  items-center">
                        <p className="invisible lg:visible text-left">Niet beschikbaar</p>
                    </div>
                </DayCard>
            }
            return;

        }

        dayCards[date] = {
            dayLabelClass: 'text-black',
            component: <DayCard>
                <div className="w-full h-full flex flex-col justify-center p-2">
                    <ul className="flex flex-col text-left gap-1">
                        {events}
                    </ul>
                </div>
            </DayCard>
        }

    })

    if (selectedDay) {
        dayCards[selectedDay] = {
            dayLabelClass: 'text-white',
            component: <DayCard>
                <div className="bg-cal-blue text-white w-full h-full flex justify-center items-center gap-2">
                    <CheckCircleIcon className="w-8 text-white" /> <span className="hidden lg:block font-proxima">Geselecteerde datum</span>
                </div>
            </DayCard>,
        }
    }

    const PassedDayCard = <DayCard>
        <div className="p-2 bg-cal-lighter-gray w-full h-full flex items-center">
            <p className="text-left ">Niet beschikbaar</p>
        </div>
    </DayCard>

    const NotAvailableYetDayCard = <DayCard>
        <div className="p-2 bg-cal-lighter-gray w-full h-full flex items-center">
            <p className="text-left">Niet beschikbaar</p>
        </div>
    </DayCard>

    return <div className="flex flex-col gap-2 items-center">
        <div className="relative w-full">
            <LoadingOverlay loading={dataLoading} />
            <Calendar
                calendarAvailableTill={calendarAvailableTill}
                dayCards={dayCards}
                NotAvailableYetDayCard={NotAvailableYetDayCard}
                onDayClick={selectDay}
                page={page}
                passedDayCard={PassedDayCard}
            />
        </div>
        <div>
            Jouw geselecteerde datum: {selectedDay == null
            ? 'kies een beschikbare datum'
            : <span className="font-bold">{DateTime
                .fromFormat(selectedDay, 'dd-MM-yyyy')
                .setLocale('nl-NL')
                .toLocaleString(DateTime.DATE_HUGE)}</span>}
        </div>
    </div>

}
